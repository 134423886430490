<template>
    <div class="container-fluid">

        <div class="row mt-4">
            <div class="col-4"></div>
            <div class="col-4">
                <div class="text-center">
                    <b>BUSCA TU CRÉDITO POR:</b>
                    <p>NOMBRE DE BENEFICIARIO, DNI O NO.PRESTAMO</p>
                </div>

                <div class="d-flex" role="search">

                    <input v-model="busqueda" class="form-control me-2" type="search" placeholder="Buscar Credito"
                        aria-label="Search">
                    <button class="btn btn-outline-success" @click="buscarCredito()" type="button">Buscar</button>

                </div>
            </div>
        </div>

        <div class="row mt-2">

            <TablaCreditos :key="`${busquedaEnviar}`" :busqueda="this.busquedaEnviar" />
        </div>


    </div>
</template>

<script>
import TablaCreditos from './TablaCreditos.vue';


export default {
    data() {
        return {
            busqueda: "",
            busquedaEnviar: "",
            ready: false,
        }
    },
    components: {
        TablaCreditos,
    },
    methods: {
        buscarCredito() {
            this.busquedaEnviar = this.busqueda
        }
    }

}
</script>

<style scoped>
.btn {
    margin: 0;
}

.btn-outline-success {
    color: #00a632;
    border-color: #00a632;
}

.btn:hover {
    margin: 0;
    background-color: #00a632;
    color: white;
}
</style>